.file-box {

    border: 1px dashed #E7E8EB;
    padding: 15px;
    display: flex;
    justify-content: center;
    border-radius: 5px;

    &.img {

        border: 0;
        position: relative;

    }

    img {

        max-width: 100%;
        height: 100%;
        max-height: 180px;
        border-radius: 5px;
        
    }

    .del-image {

        top: 0;
        background: rgba(255, 255, 255, 0.5);
        width: 100%;
        padding: 5px 0;
        display: none;
        height: 100%;
        position: absolute;
        justify-content: center;
        align-items: center;

    }

    &:hover {

        .del-image {

            display: flex;

        }

    }

}